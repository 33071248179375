import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UmbrellaPolicy = _resolveComponent("UmbrellaPolicy")!

  return (_openBlock(), _createBlock(_component_UmbrellaPolicy, {
    route: _ctx.route,
    "business-class-route": _ctx.businessClassRoute,
    "post-route": _ctx.postRoute,
    "return-route": 'broker-umbrella-policy'
  }, null, 8, ["route", "business-class-route", "post-route"]))
}